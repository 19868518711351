<template>
  <div class="full">
    <van-nav-bar :title="name" @click-left="back()">
      <template #left>
        <van-icon color="#333" :size="22" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <div class="cg_out">
      <div class="cg_inner p_r">
        <img src="../imgs/cg_inner_logo.png" alt="" class="p_a cg_inner_logo">
        <div class="ci_items_out">
          <div class="ci_items d_f ali_c" v-for="(item,index) in list" :key="index" @click="goUrlSpecial(item)">
            <img :src="item.cover" alt="">
            <div class="flex_1">
              <div class="cgi_ti">{{item.name}}</div>
              <table>
                <tr class="grey_ti">
                  <td>原价</td>
                  <td>劵后价</td>
                </tr>
                <tr class="cgi_ti_pri">
                  <td>￥<span>{{item.sellPrice?item.sellPrice/100:0}}</span></td>
                  <td>￥<span>{{item.postCouponPrice?item.postCouponPrice/100:0}}</span></td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apis from '../utils/apis';
import { NavBar, Icon, Empty, Toast } from 'vant';

export default {
    name: 'couponListGoods',
    components: {
        'van-nav-bar': NavBar,
        'van-icon': Icon,
        'van-empty': Empty,
    },
    data() {
        return {
          list:[],
          name:this.$route.query.couponName
        };
    },
    created() {
        this.loadList();
    },
    methods: {
         /**自营 票务跳转 */
    goUrl ( row) {
      this.$router.push({
        path: '/submitOrderQw',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId,
          isSelfSupport: row.sellerIsSelfSupport, //  是否自营
          isRealName: row.sellerIsRealName, //  是否实名
        }
      })
    },
    /**酒店跳转 */
    goUrlHotel ( row) {
      //
      this.$router.push({
        path: '/hotelInner',
        query: {
          commodityId: row.commodityId,
          sellerId: row.sellerId
        }
      })
    },
    /**零售跳转 */
    goUrlls ( row) {
      this.$router.push({
        path: '/goodsInfo',
        query: {
          commodityId: row.commodityId
        }
      })
    },
    /**消费劵跳转 */
    goUrlSpecial (row) {
      this.showCoupon = false
      if (row.commodityKind == 1) {
        this.goUrl(row)
      } else if (row.commodityKind == 3) {
        this.goUrlHotel( row)
      } else if (row.commodityKind == 4) {
        this.goUrlls(row)
      } else {
        Toast('未获取到商品类型，请联系管理员配置商品类型');
      }
    },
        // 分页查询优惠商品
    loadList (name) {
      this.$post2({
        url: apis.pagePreferentialCommodity,
        params: {
          "data": {
            "couponId": this.$route.query.couponId,
          },
          "pageIndex": 1,
          "pageSize": 99,
          token: this.$global.token

        },
      }).then(res => {
        console.log(res)
        this.list = res.data.data
      }).catch(err => {
        Toast(err.message);
      });
    },
        back() {
            this.$router.go(-1);
        },
    },
};
</script>

<style scoped lang="scss">
.cg_out {
    .ci_items_out {
        overflow-y: auto;
        height: 100%;
    }
    .cg_inner {
        .ci_items {
          border-bottom: 1px solid #E5E5E5;
          cursor: pointer;
            .cgi_ti_pri {
                td:last-child {
                    color: #ff4925;
                }
                font-size: 10px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #36a6ed;
                span {
                    font-size: 24px;
                }
            }
            .grey_ti {
                font-size: 10px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #777777;
            }
            table {
                table-layout: fixed;
                width: 100%;
            }
            .flex_1 {
                .cgi_ti {
                    height: 54px;
                }
                font-size: 15px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #000000;
            }
            img {
                width: 100px;
                height: 100px;
                border-radius: 5px;
                margin-right: 15px;
                object-fit: cover;
            }
            margin-bottom: 35px;
        }
        .cg_inner_logo {
            width: 250px;
            height: 77px;
            left: 39px;
            top: -36px;
        }
        height: calc(100% - 50px);
        padding: 48px 13px 0;
        background: #ffffff;
        border: 3px solid #ff4925;
        border-radius: 5px;
    }
    padding: 38px 10px;
    height: calc(100% - 50px);
    overflow: hidden;
    box-sizing: border-box;
}
.full {
    background-color: #36a6ed;
    overflow: hidden;
}
.van-nav-bar {
    background-color: white;
}
</style>
